import clonedeep from 'lodash.clonedeep'
import { themeFonts } from '@/store'
// import { setDefaultColors } from '@/components/dashboards/widgets/GraphWidget.vue'
import { // import here only what we use
  mdiFan,
  mdiTurbine,
  mdiCeilingFan,
  mdiMoleculeCo2,
  mdiFire,
  mdiHeatingCoil,
  mdiRadiator,
  mdiSprinklerFire,
  mdiLightbulb,
  mdiCeilingLight,
  mdiValve,
  mdiPipeValve,
  mdiPower,
  mdiBattery,
  mdiWater,
  mdiAirFilter,
  mdiSnowflakeThermometer,
  mdiWaterThermometer,
  mdiThermometerHigh,
  mdiAirConditioner,
  mdiWifi,
  mdiSnowflakeMelt,
  mdiCarDefrostRear,
  mdiEgg,
  mdiLinkVariant,
  mdiWeatherWindy,
  mdiHeatWave,
  mdiAngleAcute,
  mdiBarley,
  mdiWeight,
  mdiScaleUnbalanced,
  mdiSilo,
  mdiNumeric0Box,
  mdiNumeric1Box,
  mdiNumeric2Box,
  mdiNumeric3Box,
  mdiNumeric4Box,
  mdiNumeric5Box,
  mdiNumeric6Box,
  mdiNumeric7Box,
  mdiNumeric8Box,
  mdiNumeric9Box,
  mdiNumeric0Circle,
  mdiNumeric1Circle,
  mdiNumeric2Circle,
  mdiNumeric3Circle,
  mdiNumeric4Circle,
  mdiNumeric5Circle,
  mdiNumeric6Circle,
  mdiNumeric7Circle,
  mdiNumeric8Circle,
  mdiNumeric9Circle
} from '@mdi/js'

export const predefinedSymbols = [
  { name: 'Fan', value: mdiFan },
  { name: 'turbine', value: mdiTurbine },
  { name: 'ceiling-fan', value: mdiCeilingFan },
  { name: 'molecule-co2', value: mdiMoleculeCo2 },
  { name: 'fire', value: mdiFire },
  { name: 'heating-coil', value: mdiHeatingCoil },
  { name: 'radiator', value: mdiRadiator },
  { name: 'sprinkler-fire', value: mdiSprinklerFire },
  { name: 'lightbulb', value: mdiLightbulb },
  { name: 'ceiling-light', value: mdiCeilingLight },
  { name: 'valve', value: mdiValve },
  { name: 'pipe-valve', value: mdiPipeValve },
  { name: 'power', value: mdiPower },
  { name: 'battery', value: mdiBattery },
  { name: 'water', value: mdiWater },
  { name: 'air-filter', value: mdiAirFilter },
  { name: 'snowflake-thermometer', value: mdiSnowflakeThermometer },
  { name: 'water-thermometer', value: mdiWaterThermometer },
  { name: 'thermometer-high', value: mdiThermometerHigh },
  { name: 'air-conditioner', value: mdiAirConditioner },
  { name: 'wifi', value: mdiWifi },
  { name: 'snowflake-melt', value: mdiSnowflakeMelt },
  { name: 'car-defrost-rear', value: mdiCarDefrostRear },
  { name: 'egg', value: mdiEgg },
  { name: 'link-variant', value: mdiLinkVariant },
  { name: 'weather-windy', value: mdiWeatherWindy },
  { name: 'heat-wave', value: mdiHeatWave },
  { name: 'angle-acute', value: mdiAngleAcute },
  { name: 'barley', value: mdiBarley },
  { name: 'weight', value: mdiWeight },
  { name: 'scale-unbalanced', value: mdiScaleUnbalanced },
  { name: 'Silo', value: mdiSilo },
  { name: 'numeric-0-box', value: mdiNumeric0Box },
  { name: 'numeric-1-box', value: mdiNumeric1Box },
  { name: 'numeric-2-box', value: mdiNumeric2Box },
  { name: 'numeric-3-box', value: mdiNumeric3Box },
  { name: 'numeric-4-box', value: mdiNumeric4Box },
  { name: 'numeric-5-box', value: mdiNumeric5Box },
  { name: 'numeric-6-box', value: mdiNumeric6Box },
  { name: 'numeric-7-box', value: mdiNumeric7Box },
  { name: 'numeric-8-box', value: mdiNumeric8Box },
  { name: 'numeric-9-box', value: mdiNumeric9Box },
  { name: 'numeric-0-circle', value: mdiNumeric0Circle },
  { name: 'numeric-1-circle', value: mdiNumeric1Circle },
  { name: 'numeric-2-circle', value: mdiNumeric2Circle },
  { name: 'numeric-3-circle', value: mdiNumeric3Circle },
  { name: 'numeric-4-circle', value: mdiNumeric4Circle },
  { name: 'numeric-5-circle', value: mdiNumeric5Circle },
  { name: 'numeric-6-circle', value: mdiNumeric6Circle },
  { name: 'numeric-7-circle', value: mdiNumeric7Circle },
  { name: 'numeric-8-circle', value: mdiNumeric8Circle },
  { name: 'numeric-9-circle', value: mdiNumeric9Circle }
]
export const defaultSymbol = mdiFan
const defaultOptions = {
  title: {},
  animation: false,
  grid: {
    show: false,
    top: '10px',
    left: 66,
    right: 55,
    bottom: 55
  },
  tooltip: {
    show: false
  },
  toolbox: {
    show: false
  },
  legend: {
    show: false
  },
  xAxis: {
    show: true,
    data: [],
    axisTick: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false }
  },
  yAxis: {
    show: true,
    max: 0,
    min: 0,
    offset: 25,
    splitLine: { show: true }
  },
  markLine: {
    z: -100
  },
  series: [
    {
      name: 'Full',
      type: 'pictorialBar',
      animationDuration: 0,
      symbolBoundingData: 0,
      itemStyle: {
        color: '#CFCFCFFF'
      },
      data: []
    }, {
      name: 'Current value',
      type: 'pictorialBar',
      symbolClip: true,
      animationDuration: 0,
      symbolBoundingData: 0,
      z: 10,
      label: {
        show: true,
        position: 'bottom',
        width: 150,
        overflow: 'truncate',
        fontFamily: 'sans-serif',
        fontSize: 18,
        align: 'center',
        lineHeight: 18,
        offset: [0, 10],
        formatter: function(param) {
          let unit = param.data.unit
          if (unit !== '' && unit !== undefined) {
            unit = `(${param.data.unit})`
          } else if (param.data.translatedValue !== param.value) {
            unit = `{${param.data.translatedValue}}`
          }
          return `{alignCenter|${param.data.name}:}\n {b|${param.value}} {u|${unit}}`
        },
        rich: {
          alignCenter: {
            align: 'center'
          },
          b: {
            fontWeight: 'bolder'
          },
          u: {
            fontWeight: 'normal'
          }
        }
      },
      markLine: {
        data: []
      },
      markPoint: {
        data: []
      },
      data: []
    }
  ]
}

export const parseChartData = function(serverData, store, widget, containerWidth, vuetify) {
  const options = clonedeep(defaultOptions)
  const names = new Map()
  options.yAxis.show = serverData.showAxis
  options.xAxis.show = serverData.showAxis
  if (!serverData.showAxis) {
    options.grid.left = 0
    options.grid.right = 0
  }
  options.yAxis.min = serverData.min
  options.yAxis.max = serverData.max
  options.series[0].symbolBoundingData = serverData.max
  options.series[1].symbolBoundingData = serverData.max
  options.series[1].label.fontSize = store.state.settings.fontSize
  options.series[1].label.fontFamily = themeFonts[store.state.settings.themeFont].fontFamily

  options.series[1].label.width = (containerWidth - (options.grid.left + options.grid.right) - 60) / serverData.elements.length
  for (let i = 0; i < serverData.elements.length; i++) {
    const lineData = serverData.elements[i]
    if (lineData.status !== 'OK') {
      continue
    }
    const index = 1
    let name = lineData.name
    if (names.has(name)) { // in case we have multiple elements with the same name
      const entry = names.get(name)
      entry.index = entry.index + 1
      name = `${entry.index}.${name}`
      if (entry.index === 2) { // append the index to the previously found line
        options.series[1].data[entry.i].name = `1.${options.series[1].data[entry.i].name}`
        options.xAxis.data[entry.i] = `1.${options.series[1].data[entry.i].name}`
      }
    } else {
      names.set(name, { index, i: options.series[1].data.length })
    }
    let color = lineData.color
    if (color === null) {
      color = serverData.defaultColor
    }
    let backgroundColor = lineData.backgroundColor
    if (backgroundColor === null) {
      backgroundColor = serverData.defaultBackgroundColor
    }
    let symbol = lineData.symbol
    if (symbol === null) {
      symbol = serverData.defaultSymbol
    }
    options.series[0].data.push({
      value: serverData.max,
      symbolSize: ['100%', '100%'],
      symbol: `path://${symbol}`,
      line: lineData,
      itemStyle: {
        color: backgroundColor
      },
      lineStyle: {
        color: backgroundColor
      }
    })
    options.series[1].data.push({
      name: name,
      max: serverData.max,
      min: serverData.min,
      unit: lineData.unit,
      value: lineData.data,
      translatedValue: lineData.value,
      line: lineData,
      symbolSize: ['100%', '100%'],
      symbol: `path://${symbol}`,
      itemStyle: {
        color: color
      },
      lineStyle: {
        color: color
      }
    })
    if (serverData.showAverageValue) {
      options.series[1].markLine.data.push({
        type: 'average',
        label: {
          formatter: 'Average:\n {c}'
        }
      })
    }
    if (serverData.showHighestValue) {
      options.series[1].markLine.data.push({
        type: 'max',
        label: {
          formatter: 'Max:\n {c}'
        }
      })
    }
    if (serverData.showLowestValue) {
      options.series[1].markLine.data.push({
        type: 'min',
        label: {
          formatter: 'Min:\n {c}'
        }
      })
    }
    if (serverData.showCurrentValue) {
      options.series[1].markPoint.data.push({
        name: 'coordinate',
        value: lineData.data,
        itemStyle: {
          color: color
        },
        lineStyle: {
          color: color
        },
        coord: [i, lineData.data]
      })
    }
    options.xAxis.data.push(name)
  }
  return options
}
