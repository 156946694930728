<template>
  <v-dialog v-model="visible" width="600" persistent :fullscreen="fullscreen" scrollable overlay-opacity="0.65">
    <ValidationObserver ref="observer" @submit.prevent="saveAccountSettings" tag="form">
      <v-card :loading="loading">
        <v-card-title class="justify-space-between">
          <div>
            <div>{{$t("ThemeSettingsDialog.THEME_SETTINGS_MESSAGE")}}</div>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
              <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
              <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text class="pt-5">
          <v-subheader class="px-0 font-weight-black">{{ $t('ThemeSettingsDialog.THEME_MODE') }}</v-subheader>
          <div class="d-flex justify-space-between align-center pb-3">
            <div>{{ $t('ThemeSettingsDialog.THEME_MODE') }}</div>
            <v-switch
              v-model="darkTheme"
              prepend-icon="mdi-white-balance-sunny"
              color="primary"
              :false-value="false"
              :true-value="true"
              hide-details
              class="mt-0"
            >
              <template v-slot:label>
                <v-icon>mdi-moon-waning-crescent</v-icon>
              </template>
            </v-switch>
          </div>
          <v-divider class="pt-4"></v-divider>
          <v-subheader class="px-0 font-weight-black">Typography</v-subheader>
          <ValidationProvider rules="required" :name="$t('ThemeSettingsDialog.FONT_MESSAGE')" v-slot="{ errors, valid }">
            <v-select
              v-model="personalSettings.themeFont"
              :items="availableThemeFonts"
              :label='$t("ThemeSettingsDialog.FONT_MESSAGE")'
              required
              item-text="text"
              item-value="value"
              :error-messages="errors"
              :success="valid"
            >
              <template v-slot:item="{ item }">
                <div :style="createFontPreview(item)">
                  {{item.text}}
                </div>
              </template>
            </v-select>
          </ValidationProvider>
          <ValidationProvider rules="required" :name="$t('ThemeSettingsDialog.SIZE_MESSAGE')" v-slot="{ errors, valid }">
            <v-select
              v-model="personalSettings.themeSize"
              :items="availableThemeSizes"
              :label="$t('ThemeSettingsDialog.SIZE_MESSAGE')"
              required
              item-text="text"
              item-value="value"
              :error-messages="errors"
              :success="valid"
            >
              <template v-slot:item="{ item }">
                <div :style="createFontSizePreview(item)">
                  {{ item.text }}
                </div>
              </template>
            </v-select>
          </ValidationProvider>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex w-100">
            <div class="pr-1 w-100">
              <v-btn outlined block color="accent" @click="visible = false">{{$t("ThemeSettingsDialog.CANCEL_MESSAGE")}}</v-btn>
            </div>
            <div class="pl-1 w-100">
              <v-btn outlined block color="primary" type="submit">{{$t("ThemeSettingsDialog.SAVE_MESSAGE")}}</v-btn>
            </div>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { updateThemeSettings } from '@/api/backend.js'
import { themeFonts, themeSizes } from '@/store/index.js'
import { UPDATE_THEME_SETTINGS } from '@/store/mutations'
import LocalStorage from '@/plugins/local-storage.js'

export default {
  name: 'ThemeSettingsDialog',
  props: {
    isVisible: Boolean
  },
  components: {
    ValidationObserver, ValidationProvider
  },
  data: function() {
    return {
      fullscreen: false,
      loading: false,
      personalSettings: this.$store.state.settings,
      availableThemeFonts: [
        { text: 'Roboto', value: 'ROBOTO' },
        { text: 'Open Sans', value: 'OPEN_SANS' },
        { text: 'Lato', value: 'LATO' },
        { text: 'Montserrat', value: 'MONTSERRAT' },
        { text: 'Oswald', value: 'OSWALD' },
        { text: 'Source Sans Pro', value: 'SOURCE_SANS_PRO' },
        { text: 'Slabo 27px', value: 'SLABO_27PX' },
        { text: 'Raleway', value: 'RALEWAY' },
        { text: 'Pt Sans', value: 'PT_SANS' },
        { text: 'Merriweather', value: 'MERRIWEATHER' },
        { text: 'Nunito Sans', value: 'NUNITO_SANS' },
        { text: 'Prompt', value: 'PROMPT' },
        { text: 'Work Sans', value: 'WORK_SANS' }
      ],
      availableThemeSizes: [
        { text: this.createFontSizeLabel('Extra small', 'EXTRA_SMALL'), value: 'EXTRA_SMALL' },
        { text: this.createFontSizeLabel('Small', 'SMALL'), value: 'SMALL' },
        { text: this.createFontSizeLabel('Normal', 'NORMAL'), value: 'NORMAL' },
        { text: this.createFontSizeLabel('Large', 'LARGE'), value: 'LARGE' },
        { text: this.createFontSizeLabel('Extra large', 'EXTRA_LARGE'), value: 'EXTRA_LARGE' }
      ]
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    },
    darkTheme: {
      get() {
        return this.$vuetify.theme.dark
      },
      set(value) {
        if (value) {
          LocalStorage.put('theme', 'dark')
        } else {
          LocalStorage.put('theme', 'light')
        }
        this.$vuetify.theme.dark = value
      }
    },
    themeSettings() {
      return {
        size: this.personalSettings.themeSize,
        font: this.personalSettings.themeFont
      }
    }
  },
  methods: {
    saveAccountSettings() {
      this.loading = true
      updateThemeSettings(this.themeSettings).then(r => {
        this.visible = false
        this.showSuccessNotification(this.$t('ThemeSettingsDialog.THEME_SETTINGS_SAVED_MESSAGE'))
        this.$store.dispatch(UPDATE_THEME_SETTINGS, this.themeSettings)
      }).catch(e => this.showErrorNotification(this.$t('ThemeSettingsDialog.THEME_SETTINGS_SAVED_ERROR_MESSAGE')))
        .then(e => { this.loading = false })
    },
    createFontPreview(font) {
      const fontData = themeFonts[font.value]
      if (fontData !== undefined) {
        fontData.importRule()
        return `font-family: ${fontData.fontFamily} !important;`
      }
      return ''
    },
    createFontSizePreview(size) {
      const sizeData = themeSizes[size.value]
      if (sizeData !== undefined) {
        return `font-size: ${sizeData.fontSize}px !important;`
      }
      return ''
    },
    createFontSizeLabel(size, value) {
      const sizeData = themeSizes[value]
      if (sizeData !== undefined) {
        return `${size} (${sizeData.fontSize}px)`
      }
      return size
    }
  },
  watch: {
    isVisible(newValue, oldValue) {
      if (newValue) {
        this.personalSettings = this.clone(this.$store.state.settings)
      }
    }
  }
}
</script>
