<template>
  <v-dialog
    max-width="600" v-model="visible"
    scrollable
    persistent
    :fullscreen="fullscreen"
    overlay-opacity="0.65">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on" />
    </template>
    <v-card :loading="loading">
      <v-card-title class="justify-space-between">
        <div>
          <v-icon>mdi-sort</v-icon>
          <span class="pl-2">{{ $t("DashboardSortingDialog.TITLE") }}</span>
        </div>
        <div>
          <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
            <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
            <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
          </v-btn>
          <v-btn icon small @click="visible = false" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text  class="d-flex flex-column" v-if="dashboardsAndGroups.length">
        <v-select
          v-model="selectedSortingMode"
          :items="availableSortingModes"
          :label="$t('DashboardSortingDialog.SORTING_MODE_SELECT')"
          @change="updateDashboardAndGroupsOrder"
        ></v-select>
        <v-select
          v-if="selectedSortingMode === 'NAME'"
          v-model="selectedSortingDirection"
          :items="availableSortingDirections"
          :label="$t('DashboardSortingDialog.SORTING_DIRECTION_SELECT')"
          @change="updateDashboardAndGroupsOrder"
        ></v-select>
        <v-card class="overflow-y-auto mb-2 fill-height align-content-stretch">
          <v-list dense>
            <draggable :list="dashboardsAndGroups" :group="{ name: 'group-elements' }" tag="ul" handle=".handle" class="pa-0">
              <template v-for="element in dashboardsAndGroups">
                <v-list-group
                  v-model="element.expanded"
                  :key="element.id"
                  :value="element.expanded"
                  active-class="black--text"
                  :append-icon="element.dashboards? 'mdi-chevron-up' : ''">
                  <template v-slot:activator>
                    <v-list-item>
                      <v-list-item-icon v-if="selectedSortingMode === 'CUSTOM'" class="handle mr-3">
                        <v-icon>mdi-drag-horizontal-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{element.name}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item-action v-if="element.dashboards">
                      <v-speed-dial v-model="element.open" left direction="left" transition="slide-x-reverse-transition" :close-on-click="false">
                        <template v-slot:activator>
                          <v-btn color="primary" outlined x-small fab @click.stop="element.open = !element.open">
                            <v-icon v-if="element.open" class="mr-0 ml-0">mdi-close</v-icon>
                            <v-icon v-else> mdi-dots-vertical </v-icon>
                          </v-btn>
                        </template>
                        <v-btn fab x-small class="ml-1 mr-0" color="accent" @click.stop="deleteDashboardsGroup(element)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn fab x-small class="mx-1" color="primary" @click.stop="openCreateDashboardsGroupDialog(element)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-speed-dial>
                    </v-list-item-action>
                  </template>
                  <v-list-item v-if=" element.dashboards && element.dashboards.length === 0">
                    <v-list-item-title class="h-100 pl-6">{{ $t('DashboardSortingDialog.NO_DASHBOARDS_AVAILABLE') }}</v-list-item-title>
                  </v-list-item>
                  <draggable v-if="element.dashboards" :list="element.dashboards" :group="{ name: 'dashboard-elements' }" tag="ul" handle=".handle">
                    <v-list-item v-for="dashboard in element.dashboards" :key="dashboard.id" color="primary" link>
                      <v-list-item-icon v-if="selectedSortingMode === 'CUSTOM'" class="handle mr-3">
                        <v-icon>mdi-drag-horizontal-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{dashboard.name}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list-group>
              </template>
            </draggable>
          </v-list>
        </v-card>
        <v-btn block outlined color="green" @click="openCreateDashboardsGroupDialog(null)">Create group</v-btn>
      </v-card-text>
      <v-card-text v-else class="align-center d-flex justify-center pt-4">
        <div>{{$t('DashboardSortingDialog.NO_DASHBOARDS_AVAILABLE')}}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex w-100">
        <div class="pr-1 w-100">
          <v-btn outlined block color="accent" @click="resetAndHide">{{$t("DashboardSortingDialog.CANCEL_MESSAGE")}}</v-btn>
        </div>
        <div class="pl-1 w-100" v-if="dashboardsAndGroups.length">
          <v-btn outlined block color="primary" @click="applySorting">{{$t("DashboardSortingDialog.APPLY_MESSAGE")}}</v-btn>
        </div>
      </v-card-actions>
      <DashboardsGroupDialog
        :isVisible="showDashboardsGroupDialog"
        :title="dashboardsGroupTitle"
        :loading="loading"
        :dashboardsGroup="dashboardsGroup"
        @closed="showDashboardsGroupDialog = !showDashboardsGroupDialog"
        @save="saveDashboardsGroup"
      ></DashboardsGroupDialog>
    </v-card>
  </v-dialog>
</template>
<script>
import { UPDATE_DASHBOARDS_AND_GROUPS_ORDER, DELETE_DASHBOARDS_GROUP, UPDATE_DASHBOARDS_GROUP } from '@/store/mutations'
import { HandleDirective } from 'vue-slicksort'
import draggable from 'vuedraggable'
import DashboardsGroupDialog from '@/components/dashboards/sorting/DashboardsGroupDialog'

export default {
  name: 'DashboardSortingDialog',
  components: {
    draggable,
    DashboardsGroupDialog
  },
  directives: { handle: HandleDirective },
  data() {
    return {
      open: false,
      visible: false,
      loading: false,
      fullscreen: false,
      selectedSortingMode: this.$store.state.settings.dashboardsSettings.sortMode,
      selectedSortingDirection: this.$store.state.settings.dashboardsSettings.sortDirection,
      showDashboardsGroupDialog: false,
      dashboardsAndGroups: [],
      dashboardsGroupTitle: null,
      dashboardsGroup: null
    }
  },
  computed: {
    availableSortingModes() {
      return [
        {
          text: this.$t('DashboardSortingDialog.SORTING_TYPE.CUSTOM'),
          value: 'CUSTOM'
        },
        {
          text: this.$t('DashboardSortingDialog.SORTING_TYPE.NAME'),
          value: 'NAME'
        }
      ]
    },
    availableSortingDirections() {
      return [
        {
          text: this.$t('DashboardSortingDialog.SORTING_DIRECTION.ASC'),
          value: 'ASC'
        },
        {
          text: this.$t('DashboardSortingDialog.SORTING_DIRECTION.DESC'),
          value: 'DESC'
        }
      ]
    }
  },
  methods: {
    resetAndHide() {
      this.visible = false
    },
    updateDashboardAndGroupsOrder() {
      if (this.selectedSortingMode === 'NAME') {
        const direction = this.selectedSortingDirection === 'ASC' ? 1 : -1
        this.dashboardsAndGroups.sort((l, r) => l.name.localeCompare(r.name) * direction)
        this.dashboardsAndGroups.forEach((dg) => {
          if (dg.dashboards) {
            dg.dashboards.sort((l, r) => l.name.localeCompare(r.name) * direction)
          }
        })
      }
    },
    applySorting() {
      this.loading = true
      const settings = {
        sortData: {},
        sortMode: this.selectedSortingMode,
        sortDirection: this.selectedSortingDirection
      }
      this.dashboardsAndGroups.forEach((e, i) => { // dashboard id it's unique and a dashboard cannot be part of multiple groups
        const group = !!e.dashboards
        settings.sortData[e.id] = { order: i, group: group }
        if (group) {
          e.dashboards.forEach((d, j) => {
            settings.sortData[d.id] = { order: j, group: false }
          })
        }
      })
      this.$store.dispatch(`dashboards/${UPDATE_DASHBOARDS_AND_GROUPS_ORDER}`, settings).then(r => {
        this.showSuccessNotification(this.$t('DashboardSortingDialog.SORTING_APPLIED_SUCCESSFULLY'))
        this.resetAndHide()
      }).catch((e) => this.showErrorNotification(this.$t('DashboardSortingDialog.ERROR_WHILE_SAVING')))
        .then(() => { this.loading = false })
    },
    saveDashboardsGroup(group) {
      this.loading = true
      this.$store.dispatch(`dashboards/${UPDATE_DASHBOARDS_GROUP}`, group).then(r => {
        this.showSuccessNotification(this.$t('DashboardsGroupDialog.GROUP_SUCCESSFULLY_UPDATED_MESSAGE'))
        this.showDashboardsGroupDialog = false
        // sync the groups and dashboards
        const linkedDashboards = []
        let added = r.dashboards
        let matchedGroup = this.dashboardsAndGroups.find(g => g.id === r.id)
        if (matchedGroup) {
          const removed = matchedGroup.dashboards.filter(o => !r.dashboards.some(n => n.id === o.id))
          removed.forEach(d => {
            const index = matchedGroup.dashboards.findIndex(e => e.id === d.id)
            matchedGroup.dashboards.splice(index, 1)
            d.groupId = null
            this.dashboardsAndGroups.push(d)
          })
          added = r.dashboards.filter(o => !matchedGroup.dashboards.some(n => n.id === o.id))
        } else {
          matchedGroup = {}
          matchedGroup.dashboards = []
          this.dashboardsAndGroups.push(matchedGroup)
        }
        matchedGroup.id = r.id
        matchedGroup.name = group.name
        added.forEach(d => {
          const index = this.dashboardsAndGroups.findIndex(e => e.id === d.id)
          const dashboard = this.dashboardsAndGroups[index]
          dashboard.groupId = r.id
          this.dashboardsAndGroups.splice(index, 1)
          linkedDashboards.push(dashboard)
        })
        matchedGroup.dashboards.push(...linkedDashboards)
      }).catch(e => this.showErrorNotification(this.$t('DashboardsGroupDialog.COULD_NOT_UPDATE_GROUP_ERROR_MESSAGE')))
        .then(e => { this.loading = false })
    },
    deleteDashboardsGroup(dashboardsGroup) { // removed the group from current copy and from store
      this.showConfirmationDialog(this.$t('DashboardSortingDialog.DELETE_DASHBOARDS_GROUP_CONFIRMATION_MESSAGE', [dashboardsGroup.name]), true).then(r => {
        this.$store.dispatch(`dashboards/${DELETE_DASHBOARDS_GROUP}`, dashboardsGroup.id).then(() => {
          this.showSuccessNotification(this.$t('DashboardSortingDialog.DELETE_DASHBOARDS_GROUP_SUCCESS_MESSAGE'))
          const index = this.dashboardsAndGroups.findIndex(dashboardsGroup)
          if (index >= 0) {
            this.dashboardsAndGroups.splice(index, 1)
          }
        }).catch((e) => this.showErrorNotification(this.$t('DashboardSortingDialog.DELETE_DASHBOARDS_GROUP_ERROR_MESSAGE')))
      })
    },
    openCreateDashboardsGroupDialog(dashboardsGroup) {
      this.dashboardsGroup = dashboardsGroup
      this.dashboardsGroupTitle = this.$t('DashboardsGroupDialog.CREATE_GROUP_TITLE')
      if (dashboardsGroup) {
        this.dashboardsGroupTitle = this.$t('DashboardsGroupDialog.EDIT_GROUP_TITLE')
      }
      this.showDashboardsGroupDialog = true
    }
  },
  watch: {
    visible(newValue) {
      this.$emit('input', newValue) // emit to make account menu unclickable
      if (newValue) {
        this.dashboardsAndGroups = []
        this.selectedSortingMode = this.$store.state.settings.dashboardsSettings.sortMode
        this.selectedSortingDirection = this.$store.state.settings.dashboardsSettings.sortDirection

        this.$store.state.dashboards.dashboardsAndGroups.forEach((d) => {
          const data = this.clone(d)
          data.expanded = false
          if (data.dashboards) {
            data.open = false
          }
          this.dashboardsAndGroups.push(data)
        })
      }
    }
  }
}
</script>
<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.sortable-ghost {
  opacity: 1 !important;
  background: #6d6e6e !important;
}
.ghost {
  opacity: 1 !important;
  background: #6d6e6e !important;
}
.v-btn--fab.v-size--x-small {
  height: 25px !important;
  width: 25px !important;
}
</style>
